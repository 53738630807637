import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Tooltip, Typography } from "@mui/material";
import HorizontalLine from "../../components/divider";
import CopyToClipboard from "react-copy-to-clipboard";
import { Copyicon, ClockIcon } from "../../assets/images";
import { ThreeDotsinCenter } from "../../utils/function/OverviewFunction";
import { useDispatch, useSelector } from "react-redux";
import DownloadingIcon from "@mui/icons-material/Downloading";
import { getDownload } from "../../redux/node/actions";
import { format } from "timeago.js";
import { ReactComponent as ExclaminationIcon } from "../../assets/images/exclamination.svg";
import { SCALE, SCALED, SCaled } from "../../vars/scale";
import DoneIcon from "@mui/icons-material/Done";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { SET_ADD_NETWORK } from "../../redux/node/actionTypes";
import converter from "../../utils/converter";

// Extend dayjs with the required plugins
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const BoxStyle = {
  width: "100%",
  backgroundColor: " #EAF0F7",
  m: "2rem 0 1rem 0",
  borderRadius: "5px",
};
const TopBorderStyle = {
  width: "100%",
  background: " #013EB7",
  borderRadius: "5px",
  height: "3px",
};
const StartStyle = {
  height: "100%",
  maxHeight: "30px",
  padding: ".5rem 1rem",
  width: "100%",
  maxWidth: "76px",
  backgroundColor: "#FADB42",
  borderRadius: "5px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  whiteSpace: "normal",
  wordBreak: "normal",
};
const ImgBox = {
  maxWidth: "16px",
  maxHeight: "16px",
  height: "100%",
  width: "100%",
  marginRight: ".4rem",
};
const CopyBox = {
  height: "14px",
  width: "14px",
};
const ImgStyle = { height: "auto", width: "100%" };
const ClockBox = {
  display: "flex",
  "@media(max-width:395px)": {
    marginTop: ".5rem",
  },
};
const FirstFlex = { flex: "0 0 40%" };
const SecondFlex = {
  // flex: "0 0 60%",
  whiteSpace: "pre-wrap",
  wordBreak: "break-word",
  "@media(max-width:395px)": {
    marginLeft: ".5rem",
  },
};
const FlexBoxStyle = {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
};
const UserTupoStyle = {
  ...SecondFlex,
  display: "flex",
  alignItems: "center",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  minHeight: "18px",
};
const TypoStyle = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  width: { xs: "100%", sm: "200px", md: "230px", lg: "90%" },
  textOverflow: "ellipsis",
};
const TypoStyle2 = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  maxWidth: { xs: "100%", sm: "200px", md: "230px", lg: "100%" },
  textOverflow: "ellipsis",
};
const PauseButton = {
  "& .MuiButton-startIcon": { marginRight: "2px" },
  // bgcolor: "#FADB42",
  // maxWidth: "70px",
  minWidth: "70px",
  padding: ".2rem",
  maxHeight: "24px",

  display: "flex",
  // justifyContent: "center",
  alignItems: "center",
  borderRadius: "5px",
  boxShadow: " 0px 7.508px 10.5112px rgba(0, 0, 0, 0.07)",
  color: "#000",
  "&:hover": {
    backgroundColor: "#FADB42",
  },
};

// test
const text1text = "0xa9faa38e5b30460961609409faa38e5b304609616094";
const OverViewContent = () => {
  const [isCopied, setIsCopied] = useState(false);
  const [isCopy, setIsCopy] = useState(false);
  const { addNetwork, addNetworkLoading } = useSelector((state) => state.node);
  const dispatch = useDispatch();

  const data =
    addNetwork?.nodeDetails?.app_state?.genutil?.gen_txs[0]?.body?.memo;
  const modifiedData = data?.replace(/@[\w.:]+$/, "");

  //  Show the copy and copied funcitionality
  const handleCopy = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 4000);
  };
  const handleCopyData = () => {
    setIsCopy(true);
    setTimeout(() => {
      setIsCopy(false);
    }, 4000);
  };

  const downloadFile = (data, fileName) => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  };

  const handleDownload = (data) => {
    dispatch(getDownload(data));
  };

  const userAddress = addNetwork?.nodeDetails?.app_state?.auth?.accounts[0]
    ?.address
    ? addNetwork?.nodeDetails?.app_state?.auth?.accounts[0]?.address
    : addNetwork?.nodeDetails?.app_state?.auth?.accounts[0]?.base_account
        ?.address;

        const formattedAddress =
        addNetwork?.node_type === "catena"
          ? converter("creata")?.toHex(userAddress?.toString())?.toLowerCase()
          : userAddress?.toString()?.toLowerCase();

  return (
    <React.Fragment>
      <Box sx={{ overflow: "hidden" }}>
        <Box sx={BoxStyle}>
          <Box sx={TopBorderStyle} />{" "}
          <Box
            sx={{
              padding: { xs: "2rem 1rem", sm: "2rem 1rem", md: "2rem 1.5rem" },
            }}
          >
            <Typography variant="h3">Node information</Typography>
            <Typography variant="subtitle2" mt={2.2} mb={5} lineHeight="22px">
              Below you can view all the metadata for this node and other
              important information you may wish to copy.
            </Typography>
            <Box sx={FlexBoxStyle}>
              <Typography variant="h4" sx={FirstFlex}>
                Name:
              </Typography>
              <Typography variant="subtitle2" sx={SecondFlex}>
                {
                  addNetwork?.nodeDetails?.app_state?.genutil?.gen_txs[0]?.body
                    ?.messages[0]?.description?.moniker
                }
              </Typography>
            </Box>
            <HorizontalLine />
            <Box sx={FlexBoxStyle}>
              <Typography variant="h4" sx={FirstFlex}>
                Type:
              </Typography>
              <Typography variant="subtitle2" sx={SecondFlex}>
                {addNetwork?.node_type === "catena"
                  ? "Catena (CIP-20) Chain"
                  : "Zenith Chain"}
              </Typography>
            </Box>
            <HorizontalLine />
            <Box sx={FlexBoxStyle}>
              <Typography variant="h4" sx={FirstFlex}>
                Chain ID:
              </Typography>
              <Typography variant="subtitle2" sx={SecondFlex}>
                {addNetwork?.nodeDetails?.chain_id}
              </Typography>
            </Box>
            <HorizontalLine />
            <Box sx={FlexBoxStyle}>
              <Typography variant="h4" sx={FirstFlex}>
                Node Size:
              </Typography>
              <Typography variant="subtitle2" sx={SecondFlex}>
                {
                  addNetwork?.nodeDetails?.app_state?.auth?.params
                    ?.tx_size_cost_per_byte
                }
              </Typography>
            </Box>
            <HorizontalLine />
            {/* <Box sx={FlexBoxStyle}>
              <Typography variant="h4" sx={FirstFlex}>
                Owner:
              </Typography>
              <Typography variant="subtitle2" sx={SecondFlex}>
                {
                  addNetwork?.nodeDetails?.app_state?.genutil?.gen_txs[0]?.body
                    ?.messages[0]?.description?.moniker
                }
              </Typography>
            </Box>
            <HorizontalLine /> */}
            <Box sx={FlexBoxStyle}>
              <Typography variant="h4" sx={FirstFlex}>
                Status:
              </Typography>
              <Box sx={{ ...SecondFlex, my: "-.4rem" }}>
                <Box
                  sx={{
                    bgcolor: "green",
                    maxWidth: "70px",
                    minWidth: "70px",
                    padding: ".2rem",
                    maxHeight: "24px",

                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "5px",
                    boxShadow: " 0px 7.508px 10.5112px rgba(0, 0, 0, 0.07)",
                  }}
                >
                  {/* <Box sx={{ mr: "2px", width: "12px" }}>
                    <ExclaminationIcon width="100%" height="100%" />
                  </Box> */}
                  <Typography
                    sx={{ fontSize: "11px", fontWeight: 400, color: "#fff" }}
                  >
                    Configured
                  </Typography>
                  {/* <DoneIcon sx={{ color: "#fff", width: "18px" }} /> */}
                </Box>
              </Box>
            </Box>
            <HorizontalLine />
            {/* <Box sx={FlexBoxStyle}>
              <Typography variant="h4" sx={FirstFlex}>
                Region:
              </Typography>
              <Typography variant="subtitle2" sx={SecondFlex}>
                AWS: eu-central-1
              </Typography>
            </Box> */}
            {/* <HorizontalLine /> */}
            <Box sx={FlexBoxStyle}>
              <Typography variant="h4" sx={FirstFlex}>
                Creation Date:
              </Typography>
              <Box sx={{ ...SecondFlex, display: "flex", flexWrap: "wrap" }}>
                {/* <Typography variant="subtitle2" mr={1.2}>
                  {addNetwork?.createdAt}
                </Typography> */}
                <Box sx={ClockBox}>
                  <Box sx={ImgBox}>
                    <img src={ClockIcon} alt="clock logo" style={ImgStyle} />
                  </Box>
                  <Typography variant="subtitle2">
                    {/* {format(addNetwork?.createdAt)} (
                    {new Date(addNetwork?.createdAt).toUTCString()}) */}

                    {dayjs(addNetwork?.createdAt)
                      .utc()
                      .format("MMM-DD-YYYY hh:mm:ss A +[UTC]")}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <HorizontalLine />
            <Box sx={FlexBoxStyle}>
              <Typography variant="h4" sx={FirstFlex}>
                Last Updated Date:
              </Typography>
              <Box sx={{ ...SecondFlex, display: "flex", flexWrap: "wrap" }}>
                {/* <Typography variant="subtitle2" mr={1.2}>
                  {addNetwork?.updatedAt}
                </Typography> */}
                <Box sx={ClockBox}>
                  <Box sx={ImgBox}>
                    <img src={ClockIcon} alt="clock logo" style={ImgStyle} />
                  </Box>
                  <Typography variant="subtitle2">
                    {/* {format(addNetwork?.updatedAt)} (
                    {new Date(addNetwork?.updatedAt).toUTCString()}) */}

                    {dayjs(addNetwork?.updatedAt)
                      .utc()
                      .format("MMM-DD-YYYY hh:mm:ss A +[UTC]")}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <HorizontalLine />
            <Box sx={FlexBoxStyle}>
              <Typography variant="h4" sx={FirstFlex}>
                Blockchain Node ID:
              </Typography>
              <Box
                sx={{
                  ...SecondFlex,
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <Typography variant="subtitle2" mr={1.2}>
                  {/* This function will add three donts in the center of the string */}
                  {ThreeDotsinCenter(modifiedData)}
                </Typography>
                {/* Copytochlipboard will copy the text on click  */}
                <CopyToClipboard text={modifiedData} onCopy={handleCopyData}>
                  {/* It will show the copy tooltop on hover and Copied on click */}
                  {/* <Tooltip title={isCopied ? "Copied" : "Copy"}> */}
                  <span style={{ cursor: "pointer" }}>
                    <Box sx={CopyBox}>
                      {isCopy ? (
                        <DoneIcon
                          style={{
                            color: "green",
                            fontSize: "16px",
                          }}
                        />
                      ) : (
                        <ContentCopyIcon
                          style={{
                            color: "#000",
                            fontSize: "16px",
                          }}
                        />
                      )}
                    </Box>
                  </span>
                  {/* </Tooltip> */}
                </CopyToClipboard>
              </Box>
            </Box>
            <HorizontalLine />
            <Box sx={FlexBoxStyle}>
              <Typography variant="h4" sx={FirstFlex}>
                Chain Supply:
              </Typography>
              <Typography variant="subtitle2" sx={SecondFlex}>
                {addNetwork?.node_type === "catena"
                  ? addNetwork?.nodeDetails?.app_state?.bank?.supply[0]
                      ?.amount / SCALED
                  : addNetwork?.nodeDetails?.app_state?.bank?.supply[0]
                      ?.amount / SCALE}{" "}
                CTA
              </Typography>
            </Box>
            <HorizontalLine />
            <Box sx={FlexBoxStyle}>
              <Typography variant="h4" sx={FirstFlex}>
                Initial Staked Amount:
              </Typography>

              <Typography variant="subtitle2" sx={SecondFlex}>
                {addNetwork?.node_type === "catena"
                  ? Math.round(
                      addNetwork?.nodeDetails?.app_state?.genutil?.gen_txs[0]
                        ?.body?.messages[0]?.value?.amount / SCALED
                    )
                  : addNetwork?.nodeDetails?.app_state?.genutil?.gen_txs[0]
                      ?.body?.messages[0]?.value?.amount / SCALE}{" "}
                CTA
              </Typography>
            </Box>
            <HorizontalLine />

            {/* <Box sx={FlexBoxStyle}>
              <Typography variant="h4" sx={FirstFlex}>
                Blockchain Node ID:
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  ...SecondFlex,
                  whiteSpace: "pre-wrap",
                  wordBreak: "break-word",
                }}
              >
                {ThreeDotsinCenter(text1text)}
              </Typography>
            </Box>
            <HorizontalLine /> */}
            {/* <Box sx={FlexBoxStyle}>
              <Typography variant="h4" sx={FirstFlex}>
                Runtime version:
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{ ...SecondFlex, ...TypoStyle }}
              >
                Geth/v1.10.18-stable-e0109cee/linux-amd64/go1.18.7
              </Typography>
            </Box>
            <HorizontalLine /> */}
            <Box sx={FlexBoxStyle}>
              <Typography variant="h4" sx={FirstFlex}>
                User Address:
              </Typography>
              <Box sx={UserTupoStyle}>
                <Typography variant="subtitle2" mr={1.2} sx={TypoStyle}>
                  {formattedAddress}
                </Typography>
                {/* Copytochlipboard will copy the text on click  */}
                <CopyToClipboard text={formattedAddress} onCopy={handleCopy}>
                  {/* It will show the copy tooltop on hover and Copied on click */}
                  {/* <Tooltip title={isCopied ? "Copied" : "Copy"}> */}
                  <span style={{ cursor: "pointer" }}>
                    <Box sx={CopyBox}>
                      {isCopied ? (
                        <DoneIcon
                          style={{
                            color: "green",
                            fontSize: "16px",
                          }}
                        />
                      ) : (
                        <ContentCopyIcon
                          style={{
                            color: "#000",
                            fontSize: "16px",
                          }}
                        />
                      )}
                    </Box>
                  </span>
                  {/* </Tooltip> */}
                </CopyToClipboard>
              </Box>
            </Box>
            <HorizontalLine />
            {/* <Box sx={FlexBoxStyle}>
              <Typography variant="h4" sx={FirstFlex}>
                Download File:
              </Typography>
              <Box
                sx={UserTupoStyle}
                onClick={() => handleDownload(addNetwork?.dirPath)}
              >
                <Typography
                  variant="subtitle2"
                  mr={1.2}
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <DownloadingIcon /> &nbsp;{" "}
                  {!addNetworkLoading ? (
                    " Configuration"
                  ) : (
                    <CircularProgress size="1rem" />
                  )}
                </Typography>
              </Box>
            </Box> */}
          </Box>{" "}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default OverViewContent;
